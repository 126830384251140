<t4-list-view #listVw [options]="listOptions" (onDataRequest)="doPage($event)">
  <ng-container *t4ListItem="let item">
    <ng-container [ngSwitch]="item.ProductType">
      <ng-container *ngSwitchCase="ProductTypeEnum.Ticket">

        <td *ngIf="item.PassePartoutName == null">
          {{item.EventDate | localDateTime }} - {{item.EventName}}
        </td>
        <td *ngIf="item.PassePartoutName != null">{{ item.PassePartoutName | replaceOnEmpty:''}}</td>
        <td>{{item.ParentSectionName}} / {{item.RowNumber | replaceOnEmpty}}
          / {{item.SeatNumber | replaceOnEmpty}}</td>
        <td>{{ item.SelectedSingleEntranceName | replaceOnEmpty:'[Standaard]' }}</td>
        <td>{{ item.TicketText | replaceOnEmpty:'[Standaard]' }}</td>
        <td>{{item.TicketTypeName}}</td>
        <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructOrderCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
        <td *ngIf="hasTicketStatus && !isInEditMode && item.TicketStatus"><t4-ticket-iba-status *ngIf="!isSecondaryOrder || !item.IsCredited" [ticket]="item"></t4-ticket-iba-status></td>
        <td *ngIf="!isSecondaryOrder">{{!item.IsCredited ? item.PriceVariationName : '-'}}</td>
        <td>{{item.Price | currency:'EUR'}}</td>
        <td>{{item.VAT | currency:'EUR'}}</td>
        <td>{{item.Total | currency:'EUR'}}</td>
        <td *ngIf="isInEditMode">
          <button *ngIf="showEditButtons(item)" (click)="actionClick('edit', item)" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="showEditButtons(item)" (click)="actionClick('credit', item)" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="ProductTypeEnum.Membership">
        <td>{{ item.MembershipName | replaceOnEmpty}}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
        <td *ngIf="!isSecondaryOrder">{{!item.IsCredited ? item.PriceVariationName : '-'}}</td>
        <td>{{item.Price | currency:'EUR'}}</td>
        <td>{{item.VAT | currency:'EUR'}}</td>
        <td>{{item.Total | currency:'EUR'}}</td>
        <td *ngIf="isInEditMode">
          <button *ngIf="showEditButtons(item)" (click)="actionClick('edit', item)" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="showEditButtons(item)" (click)="actionClick('credit', item)" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="ProductTypeEnum.DeliveryMethod">
        <td>{{'T4.ORDERDETAILS.ORDERLINES.DELIVERY-COST' | translate}}</td>
        <td colspan="5"></td>
        <td *ngIf="hasTicketStatus && !isInEditMode"></td>
        <td *ngIf="!isSecondaryOrder"></td>
        <td>{{item.Price | currency:'EUR'}}</td>
        <td>{{item.VAT | currency:'EUR'}}</td>
        <td>{{item.Total | currency:'EUR'}}</td>
        <td *ngIf="isInEditMode">
          <button *ngIf="showEditButtons(item)" (click)="actionClick('edit', item)" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="showEditButtons(item)" (click)="actionClick('credit', item)" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="ProductTypeEnum.PaymentMethod">
        <td>{{'T4.ORDERDETAILS.ORDERLINES.MARKUP-PAYMENTMETHOD' | translate}}</td>
        <td colspan="5"></td>
        <td *ngIf="hasTicketStatus && !isInEditMode"></td>
        <td *ngIf="!isSecondaryOrder"></td>
        <td>{{item.Price | currency:'EUR'}}</td>
        <td>{{item.VAT | currency:'EUR'}}</td>
        <td>{{item.Total | currency:'EUR'}}</td>
        <td *ngIf="isInEditMode">
          <button *ngIf="showEditButtons(item)" (click)="actionClick('edit', item)" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="showEditButtons(item)" (click)="actionClick('credit', item)" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="ProductTypeEnum.CrossSellProduct">

        <td colspan="6">{{ item.CrossSellProductName }} ({{ item.CrossSellProductVariantName | translate }}) {{item.EventName}}</td>
        <td *ngIf="hasTicketStatus && !isInEditMode"></td>
        <td *ngIf="!isSecondaryOrder"></td>
        <td>{{item.Price | currency:'EUR'}}</td>
        <td>{{item.VAT | currency:'EUR'}}</td>
        <td>{{item.Total | currency:'EUR'}}</td>
        <td *ngIf="isInEditMode">
          <button *ngIf="showEditButtons(item)" (click)="actionClick('edit', item)" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="showEditButtons(item)" (click)="actionClick('credit', item)" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>

      </ng-container>

      <ng-container *ngSwitchCase="ProductTypeEnum.MatchDayPass">
        <td>{{ item.MatchDayPassName | replaceOnEmpty}}</td>
        <td>{{ item.SectorName }}</td>
        <td>-</td>
        <td>-</td>
        <td>Flexplan</td>
        <td><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
        <td *ngIf="hasTicketStatus && !isInEditMode"></td>
        <td *ngIf="!isSecondaryOrder">{{!item.IsCredited ? item.PriceVariationName : '-'}}</td>
        <td>{{item.Price | currency:'EUR'}}</td>
        <td>{{item.VAT | currency:'EUR'}}</td>
        <td>{{item.Total | currency:'EUR'}}</td>
        <td *ngIf="isInEditMode">
          <button *ngIf="showEditButtons(item)" (click)="actionClick('edit', item)" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
          <button *ngIf="showEditButtons(item)" (click)="actionClick('credit', item)" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        </td>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <td colspan="11">
          <span class="fas fa-exclamation-triangle"></span>
          <span class="mx-2">{{'T4.ORDERDETAILS.ORDERLINES.CANNOTSHOWLINE' | translate}}</span>
          <span class="fas fa-exclamation-triangle"></span>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>
</t4-list-view>
