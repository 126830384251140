import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageWarningComponent } from './../components/page/page-warn.component';
import { PageInfoComponent } from './../components/page/page-info.component';

const classesToInclude = [
  PageInfoComponent,
  PageWarningComponent
]

@NgModule({
    declarations: classesToInclude,
    imports: [
      CommonModule,
      TranslateModule
    ],
    exports: classesToInclude,
    providers: [
      TranslateService,
      PageInfoComponent,
      PageWarningComponent
    ]
  })
  export class PageModule { }