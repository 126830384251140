import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { OrderPaidStatusChipboxComponent } from "../components/chipboxes/order-paid-status-chipbox/order-paid-status-chipbox.component";

@NgModule({
    declarations: [OrderPaidStatusChipboxComponent],
    imports: [CommonModule, TranslateModule],
    exports: [OrderPaidStatusChipboxComponent],
    providers: [TranslateService]
})
export class T4ChipboxesModule {}
