import {NgModule} from '@angular/core';
import {BooleanTypePipe} from '../pipes/booleantype.pipe';
import {ExcerptPipe} from '../pipes/excerpt.pipe';
import { RelativeTimePipe } from '../pipes/relative-time.pipe';
import {ReplaceOnEmptyPipe} from '../pipes/replace-on-empty.pipe';
import {SafePipe} from '../pipes/safe.pipe';
import { localTimePipe } from '../pipes/local-time.pipe';
import { localDatePipe } from '../pipes/local-date.pipe';
import { localDateTimePipe } from '../pipes/local-datetime.pipe';
import { localDateLongPipe } from '../pipes/local-date-long.pipe';

@NgModule({
  declarations: [ExcerptPipe, SafePipe, ReplaceOnEmptyPipe, BooleanTypePipe, RelativeTimePipe, localTimePipe, localDatePipe, localDateTimePipe, localDateLongPipe],
  imports: [],
  exports: [ExcerptPipe, SafePipe, ReplaceOnEmptyPipe, BooleanTypePipe, RelativeTimePipe, localTimePipe, localDatePipe, localDateTimePipe, localDateLongPipe],
  providers: [],
})
export class PipesModule {
}
