/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthenticateContactResultEnum } from './authenticateContactResultEnum';
import { SalesChannelView } from './salesChannelView';


export interface TokenRequestModel { 
    AuthResult?: AuthenticateContactResultEnum;
    Token?: string | null;
    CurrentChannel?: SalesChannelView;
    SalesChannels?: Array<SalesChannelView> | null;
    IsArchiveMode?: boolean;
}

