import {NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {EnvironmentService} from '../services/environment.service';
import {SnackbarService} from '../services/snackbar.service';
import {EnvironmentModule} from './environment.module';


@NgModule({
  declarations: [],
  imports: [
    MatSnackBarModule,
    EnvironmentModule
  ],
  exports: [],
  providers: [
    SnackbarService,
    EnvironmentService,
    TranslateService
  ],
})
export class SnackbarModule { }
