import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ListViewComponent} from '../components/list-view/list-view.component';
import { ListValueBooleanComponent } from '../components/list-value-boolean/list-value-boolean.component';
import {ImportantItemFilterDirective} from '../directives/list-view/important-item-filter.directive';
import {ItemFilterDirective} from '../directives/list-view/item-filter.directive';
import {ListActionsDirective} from '../directives/list-view/list-actions.directive';
import { ListBannerDirective } from '../directives/list-view/list-banner.directive';
import {ListAdditionalContentDirective} from '../directives/list-view/list-additional-content-directive';
import {ListItemDirective} from '../directives/list-view/list-item-directive';
import {CustomContentDirective} from '../directives/list-view/custom-content-directive';
import {PagerModule} from './pager.module';
import {MemoViewComponent} from "../components/memo-view/memo-view.component";

@NgModule({
  declarations: [
    ListViewComponent,
    ListValueBooleanComponent,
    ImportantItemFilterDirective,
    ItemFilterDirective,
    ListActionsDirective,
    ListBannerDirective,
    ListAdditionalContentDirective,
    ListItemDirective,
    MemoViewComponent,
    CustomContentDirective
  ],
  imports: [
    CommonModule,
    PagerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    ListViewComponent,
    ListValueBooleanComponent,
    ImportantItemFilterDirective,
    ItemFilterDirective,
    ListActionsDirective,
    ListBannerDirective,
    ListAdditionalContentDirective,
    ListItemDirective,
    MemoViewComponent,
    CustomContentDirective
  ],
  providers: [],
})
export class ListViewModule {
}
