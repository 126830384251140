/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PopularProduct } from './popularProduct';
import { TurnoverPerProduct } from './turnoverPerProduct';
import { TurnOverPerPeriod } from './turnOverPerPeriod';


export interface StatisticsShopDashboardModel { 
    Interval?: string | null;
    Created?: Date;
    StartDateTime?: Date;
    EndDateTime?: Date;
    TurnOverPeriods?: Array<TurnOverPerPeriod> | null;
    Turnover?: number;
    SoldTickets?: number;
    SoldMatchDayPasses?: number;
    ActiveMembers?: number;
    ETicketDownloads?: number;
    AccountLogins?: number;
    AccountActivations?: number;
    AccountPasswordResets?: number;
    OrderCount?: number;
    AverageOrderValue?: number;
    TurnoverPerProduct?: Array<TurnoverPerProduct> | null;
    PopularProducts?: Array<PopularProduct> | null;
    Expires?: Date;
    ExpiryInSeconds?: number;
    CacheTimeInSeconds?: number;
}

