import { DatePickerDirective } from './../directives/datepicker/datepicker.directive';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [
    DatePickerDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DatePickerDirective
  ],
  providers: [
  ],
})
export class DatesModule {
}