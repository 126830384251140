import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TabComponent } from '../components/tabs/tab/tab.component';
import { TabsComponent } from '../components/tabs/tabs.component';

@NgModule({
    declarations: [
        TabsComponent,
        TabComponent
    ],
    imports: [ 
        CommonModule,
        TranslateModule
    ],
    exports: [
        TabsComponent,
        TabComponent
    ],
    providers: [
    ],
})
export class TabsModule {
}