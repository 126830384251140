/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FanOrderView } from './fanOrderView';


export interface FanOrderViewPagedResult { 
    TotalRecords?: number;
    Records?: Array<FanOrderView> | null;
    ResultCountCapped?: boolean;
}

