import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {PipesModule} from './pipes.module';
import {ConfirmComponent} from '../components/confirm/confirm.component';
import {ConfirmService} from '../services/confirm.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    PipesModule
  ],
  exports: [],
  providers: [
    ConfirmService,
    TranslateService,
    ConfirmComponent
  ]
})
export class ConfirmModule { }
