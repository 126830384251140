/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MobileTicketMarketPlaceStatusModel } from './mobileTicketMarketPlaceStatusModel';
import { MyTicketStatusEnum } from './myTicketStatusEnum';
import { TicketCategoryEnum } from './ticketCategoryEnum';
import { TicketStatusAvailableActions } from './ticketStatusAvailableActions';


export interface TicketStatusModel { 
    Status?: MyTicketStatusEnum;
    MarketPlaceStatus?: MobileTicketMarketPlaceStatusModel;
    ActionRequired?: boolean;
    AvailableActions?: TicketStatusAvailableActions;
    Category?: TicketCategoryEnum;
}

