
<ul class="nav nav-border-top bg-white" style="border-top: 1px solid #f3f3f3;">
  <ng-container *ngFor="let tab of tabs">
    <li class="nav-item clickable mt-2 mb-2" *ngIf="!tab.tabTitleHidden" (click)="selectTab(tab)" >
      <a class="nav-link" [class.active]="tab.selected">{{tab.tabTitle | translate}}</a>
    </li>
  </ng-container>
</ul>

<ng-content></ng-content>
