/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MenuItemView } from './menuItemView';
import { UserSalesChannelView } from './userSalesChannelView';


export interface FanManagementUser { 
    Id?: number;
    Firstname?: string | null;
    Middlename?: string | null;
    Lastname?: string | null;
    Fullname?: string | null;
    MenuItems?: Array<MenuItemView> | null;
    Channels?: Array<UserSalesChannelView> | null;
    ProfileImage?: string | null;
    LanguageCode?: string | null;
    ShowGoToProfile?: boolean;
}

