import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  ConfirmModule,
  ConfirmService,
  EnvironmentModule,
  EnvironmentService,
  HttpLoaderService,
  InjectComponentModule,
  InjectComponentService,
  LayoutsModule,
  LoaderModule,
  localDateTimePipe,
  LoggingService,
  PipesModule,
  RoutePartsService,
  SnackbarService,
  TranslateHttpLoader,
  VersionCheckService
} from '@tymes4-shared';
import {EmptyWithMessageComponent} from '../app/shared/layouts/empty-with-message/empty-with-message.component';
import {AuthGuard} from '../app/shared/services/auth/auth.guard';
import {AuthInterceptor} from '../app/shared/services/auth/auth.interceptor';
import {AuthService} from '../app/shared/services/auth/auth.service';
import {LanguageServiceOld} from '../app/shared/services/http/language.service';
import {StatisticsServiceOld} from '../app/shared/services/http/statistics.service';
import {AppComponent} from './app.component';
import {rootRouterConfig} from './app.routing';
import { ApiModule } from './shared/api';
import {NavHeaderComponent} from './shared/components/nav-header/nav-header.component';
import { apiConfigFactory } from './shared/config/api.config.factory';
import {SharedModule} from './shared/shared.module';
import {StaticSharedModule} from './shared/static-shared.module';
import { ApplicationModuleHelperService } from '../../../Backoffice/src/app/shared/services/application-module.service';

export function createTranslateLoader(http: HttpClient, environment: EnvironmentService) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeNl, 'nl-NL');

@NgModule({
  declarations: [
    AppComponent,
    EmptyWithMessageComponent,
    NavHeaderComponent,
    NavHeaderComponent,
  ],
  imports: [
    [ApiModule.forRoot(apiConfigFactory)],
    SharedModule,
    StaticSharedModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    MatDialogModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, EnvironmentService]
      }
    }),
    MatSnackBarModule,
    ConfirmModule,
    LayoutsModule,
    EnvironmentModule,
    PipesModule,
    LoaderModule,
    InjectComponentModule,
  ],
  providers: [
    LoggingService,
    RoutePartsService,
    VersionCheckService,
    TranslateService,
    LanguageServiceOld,
    StatisticsServiceOld,
    AuthService,
    AuthGuard,
    InjectComponentService,
    ConfirmService,
    HttpLoaderService,
    SnackbarService,
    localDateTimePipe,
    ApplicationModuleHelperService,

    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
