/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionView } from './subscriptionView';
import { OrderDiscountTotals } from './orderDiscountTotals';
import { IdentificationCustomer } from './identificationCustomer';
import { OrderLineViewWithPricing } from './orderLineViewWithPricing';


export interface FanOrderDetailsView { 
    Balance?: number;
    PendingPaymentBalance?: number;
    Payed?: boolean;
    Id?: number;
    UsedPaymentMethods?: string | null;
    OrderLines?: Array<OrderLineViewWithPricing> | null;
    OrderDate?: Date | null;
    Reference?: string | null;
    TotalInclVat?: number;
    SubscriptionViews?: Array<SubscriptionView> | null;
    Locked?: boolean;
    DeliveryMethodName?: string | null;
    PayByInvoice?: boolean;
    CustomerId?: number;
    CustomerName?: string | null;
    AllowEticketPrinting?: boolean;
    Summary?: string | null;
    ProductsSubTotalAmount?: number;
    PaymentMethodSubTotalAmount?: number;
    DeliveryMethodSubTotalAmount?: number;
    DiscountSubTotalAmount?: number;
    DiscountTotals?: Array<OrderDiscountTotals> | null;
    OrderIdentificationCustomer?: IdentificationCustomer;
}

