import { ListViewComponent } from './../../list-view/list-view.component';
import { ProductTypeEnum } from '../../../enums/product-type.enum';
import { IdentificationCustomer } from '../../../controls/customer-identification-control/customer-identification-control.component';
import { ListViewSelectionMode } from './../../../enums/list-view-selection-mode.enum';
import { Component, Input, OnInit, ViewChild, AfterViewInit, SimpleChanges, Output, EventEmitter} from "@angular/core";
import { PagedResult } from './../../../models/list-view/pagedResult';
import { ConfirmService } from '../../../services/confirm.service';

@Component({
    selector: 't4-order-lines',
    templateUrl: './order-lines.component.html',
    styleUrls: ['./order-lines.component.scss']
})
export class OrderLinesComponent implements OnInit, AfterViewInit {
    public ProductTypeEnum = ProductTypeEnum;
    public first: number;

    @Input() public orderLines: Array<any>;
    @Input() public secondaryTicketingActive: boolean = false;
    @Input() public isIbaActive: boolean = false;
    @Input() isInEditMode: boolean = false;
    @Input() public isSecondaryShop: boolean = false;
    @Input() public isSecondaryOrder: boolean = false;
    @Input() public hasPaymentLines: boolean = true;
    @Output() editOrderLineOutput = new EventEmitter<any>();
    @Output() creditOrderLineOutput = new EventEmitter<any>();

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERDETAILS.ORDERLINES.NOLINES',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        fixedTableLayout: true,
        headers: [
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.PRODUCT', hideOn: null, class: 'product-header' },
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.SECTIONROWSEAT', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.ENTRANCE', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TICKETTEXT', hideOn: null, width: 200},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TYPE', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.INTHENAMEOF', hideOn: null, width: 190}
        ]
      };

    public orderLinesLength: number;
    public pagedOrderLines: any[];
    private headersSet: boolean = false;
    public hasTicketStatus: boolean = false;

    constructor(
        private confirmService: ConfirmService,
    ) { }

    ngOnChanges(value: SimpleChanges) {
        if (value && value.orderLines && value.orderLines.currentValue !== value.orderLines.previousValue && this.listVw != undefined){
            this.orderLines = value.orderLines.currentValue ?? value.orderLines.previousValue;
            this.listVw.refreshData();
        }
        if (value && value.isInEditMode && value.isInEditMode.currentValue != value.isInEditMode.previousValue)
            this.setHeaders()
    }

    setHeaders() {
        if (!this.pagedOrderLines) {
            return;
        }

        this.listOptions.headers = [
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.PRODUCT', hideOn: null, class: 'product-header' },
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.SECTIONROWSEAT', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.ENTRANCE', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TICKETTEXT', hideOn: null, width: 200},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TYPE', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.INTHENAMEOF', hideOn: null, width: 190}
        ];

        this.hasTicketStatus = !this.isInEditMode && !this.isSecondaryOrder && this.pagedOrderLines.some((orderLine) => orderLine.TicketStatus != null && orderLine.TicketStatus.Status == "Bought");
        if (this.hasTicketStatus){
            this.listOptions.headers.push({sortField: null, label: 'T4.ORDERDETAILS.HEADER.SECONDARY_TICKETING', hideOn: null, width: 80});
        }
        
        if (!this.isSecondaryOrder) {
            this.listOptions.headers.push({sortField: null, label: 'T4.ORDERDETAILS.HEADER.CATEGORY', hideOn: null, width: 130});
        }
        this.listOptions.headers.push(
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.EXVAT', hideOn: null, width: 80},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.VAT', hideOn: null, width: 80},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TOTAL', hideOn: null, width: 80},
        );

        if (this.isInEditMode) {
            this.listOptions.headers.push({sortField: null, label: '', hideOn: null, width: 100})
        }

        this.headersSet = true;

        this.listVw.setOptions(this.listOptions)
        this.listVw.refreshData();
    }

    doPage($event) {
        this.pagedOrderLines = this.orderLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);

        let d : PagedResult = {
            TotalRecords: this.orderLinesLength,
            Records: this.pagedOrderLines
        }

        this.listVw.Data = d;
        this.first = this.pagedOrderLines[0].Id;
        if(!this.headersSet) {
            this.setHeaders();
        }
    }

    ngOnInit(): void {
        this.orderLines = this.orderLines.filter(ol => ol.ProductType !== ProductTypeEnum.Discount);

        this.orderLinesLength = this.orderLines.length;
    }

    ngAfterViewInit() {}

    constructCustomerIdentificationModel(orderLine): IdentificationCustomer {
        return {
          CalculatedName: orderLine.TicketCustomerCalculatedName,
          CustomerType: orderLine.TicketCustomerType,
          CustomerName: orderLine.TicketCustomer,
          IsIdentified: orderLine.TicketCustomerIsIdentified,
          CustomerNumber: orderLine.TicketCustomerNumber,
          TicketCustomerId: orderLine.TicketCustomerId,
        }
    }

    constructOrderCustomerIdentificationModel(orderLine): IdentificationCustomer{
        return {
            CalculatedName: orderLine.OrderCustomerCalculatedName,
            CustomerType: orderLine.OrderCustomerType,
            CustomerName: orderLine.OrderCustomer,
            IsIdentified: orderLine.OrderCustomerIsIdentified,
            CustomerNumber: orderLine.OrderCustomerNumber,
            TicketCustomerId: orderLine.OrderCustomerId,
          }
    }


    actionClick(action: string, orderLine) {
        if (action == 'edit') {
            this.editOrderLineOutput.emit(orderLine);
        }
        else if (action == 'credit') {
            this.confirmService.confirm({
                title: 'SALES.ORDERS.DETAILS.CONFIRM.CREDIT.TITLE',
                message: 'SALES.ORDERS.DETAILS.CONFIRM.CREDIT.MSG'
            }).subscribe(confirmed => {
                if (confirmed) {
                    orderLine.isCredited = true;
                    this.creditOrderLineOutput.emit(orderLine.Id);
                }
            })
        }
    }

    showEditButtons(orderLine) {
        return !orderLine.IsCredited && (this.isSecondaryOrder || !orderLine.TicketStatus || (orderLine.TicketStatus && orderLine.TicketStatus.Category != 'TicketsOnExchange'));
    }
}
