import { Component, Input, OnInit } from '@angular/core';
import { Tab } from '../../../models/tabs/tab';
import { TabsComponent } from '../tabs.component';

@Component({
  selector: 't4-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, Tab {

  @Input() public tabTitle;
  @Input() public tabTitleHidden: boolean = false;
  @Input() public id: string;
  @Input() public pos: string;
  @Input("selected")
  set selectedInit(p) {
    this.selected = p;
    this.isPreSelected = true;
  }
  public isPreSelected: boolean;
  public selected: boolean;

  constructor(private tabsComponent: TabsComponent) {}

  ngOnInit() {
    this.tabsComponent.addTab(this, this.pos);
  }

}
