import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HttpLoaderService} from '../services/http-loader.service';
import {LoaderComponent} from '../components/loader/loader.component';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [LoaderComponent],
  providers: [
    HttpLoaderService,
    LoaderComponent
  ]
})
export class LoaderModule {
}
