/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculatedPriceComponentModel } from './calculatedPriceComponentModel';


export interface CalculatedPriceVariantModel { 
    PriceExVat?: number;
    Vat?: number;
    PriceTotal?: number;
    VariantId?: number;
    VariationName?: string | null;
    VariationCode?: string | null;
    IsBaseVariation?: boolean;
    PriceListPriceId?: number;
    PriceComponents?: Array<CalculatedPriceComponentModel> | null;
}

