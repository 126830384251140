import { UserSalesChannel } from "@tymes4-shared/lib/models/session/user-sales-channel";
import { Language as SessionLanguage } from "@tymes4-shared";
import { Language, MenuItemView, SalesChannelView, UserDetailsView } from "../api";
import { AppUser } from "../models/app-user";
import { MenuItem } from "../models/menuitem";

export class MappingHelper {
    public static MapUserDetailsViewToAppUser(user : UserDetailsView) : AppUser {
        let appUser : AppUser = {
            Id: user.Id,
            Email: user.Email,
            Username: user.Username ? true : false,
            Firstname: user.Firstname,
            Middlename: user.Middlename,
            Lastname: user.Lastname,
            Fullname: user.Fullname,
            Roles: user.Roles,
            MenuItems: user.MenuItems.map(m => this.MapMenuItemViewToMenuItem(m)),
            Channels: user.Channels.map(c => this.MapSalesChannelViewToUserSalesChannel(c)),
            IsArchiveMode: user.IsArchiveMode,
            ProfileImage: user.ProfileImage,
            LanguageCode: user.LanguageCode,
            ShowGoToProfile: user.ShowGoToProfile,
            SystemSettings: user.SystemSettings,
            deserialize: function (input: any): AppUser {
                throw new Error("Function not implemented.");
            }
        };

        appUser.Channels.find(c => c.SalesChannelId == user.PrimarySalesChannelId).IsPrimary = true;
        appUser.Channels.forEach(c => c.UserId = appUser.Id);

        return appUser;
    }

    public static MapAPILanguageToSessionLanguage(language : Language) : SessionLanguage {
        const lang : SessionLanguage = {
            Id: language.Id,
            Code: language.Code,
            Name: language.Name,
            Locale: language.Locale,
            IsDefault: language.IsDefault,
            CssClass: language.CssClass,
            Created:  new Date(language.Created),
            Deleted: new Date(language.Deleted),
            Modified: new Date(language.Modified),

            TimeZone: language.TimeZone,
            FormatDate: language.FormatDate,
            FormatDateTime: language.FormatDateTime,
            FormatTime: language.FormatTime
        };

        return lang;
    }

    public static MapMenuItemViewToMenuItem(menuItemView : MenuItemView) : MenuItem {
        const menuItem : MenuItem = {
            Icon: menuItemView.Icon,
            Name: menuItemView.Name,
            State: menuItemView.State,
            SubItems: menuItemView.SubItems.map(s => this.MapMenuItemViewToMenuItem(s)),
            ToolTip: menuItemView.ToolTip,
            Type: this.MapMenuItemViewTypeToMenuItemType(menuItemView.Type)
        };
        return menuItem;
    }

    private static MapMenuItemViewTypeToMenuItemType(type : string) : 'dropDown'|'subHeader'|'link' {
        if(type === 'dropDown') {
            return 'dropDown';
        } else if(type === 'subHeader') {
            return 'subHeader';
        } else if(type === 'link') {
            return 'link';
        } else {
            throw new Error("Unknown menu item type.");
        }
    }

    public static MapSalesChannelViewToUserSalesChannel(salesChannelView : SalesChannelView) : UserSalesChannel {
        const salesChannel : UserSalesChannel = {
            HexColor: salesChannelView.HexColor,
            IsPrimary: false, //TODO
            Name: salesChannelView.Name,
            SalesChannelId: salesChannelView.Id,
            SalesGroupId: salesChannelView.SalesGroupId,
            UserId: 0, //TODO;
        }
        return salesChannel;
    }
}
