import { Component, Input, OnInit } from "@angular/core";

interface Order {
  TotalInclVat: number;
  Balance: number;
}

@Component({
  selector: 't4-order-paid-status-chipbox',
  templateUrl: './order-paid-status-chipbox.component.html'
})
export class OrderPaidStatusChipboxComponent {
  @Input({ required: true })
  public order: Order;
}
