import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DisplayValidationComponent} from '../components/forms/display-validation/display-validation.component';
import {InputLabelComponent} from '../components/forms/input-label/input-label.component';
import {LoggingService} from '../services/logging.service';

@NgModule({
  declarations: [
    DisplayValidationComponent,
    InputLabelComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    DisplayValidationComponent,
    InputLabelComponent
  ],
  providers: [
    LoggingService
  ],
})
export class FormExtensionModule {
}
