import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {PipesModule} from './pipes.module';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UploadMessageComponent } from '../components/upload-message/upload-message.component';
import { UploadMessageService } from '../services/upload-message.service';

@NgModule({
  declarations: [UploadMessageComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    PipesModule
  ],
  exports: [
    UploadMessageComponent,
    //TranslateService
  ],
  providers: [
    UploadMessageService,
    UploadMessageComponent
  ]
})
export class UploadMessageModule { }
