/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerMembershipStatusEnum } from './customerMembershipStatusEnum';


export interface FanCustomerView { 
    IsNew?: boolean;
    Id?: number;
    Name?: string | null;
    TelephoneNr?: string | null;
    CustomerNumber?: string | null;
    Addressline1?: string | null;
    PostalCode?: string | null;
    City?: string | null;
    Email?: string | null;
    Status?: CustomerMembershipStatusEnum;
    SubscriptionStatus?: string | null;
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
}

