import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InjectComponentDirective} from '../directives/inject-component.directive';
import {HttpLoaderService} from '../services/http-loader.service';
import {InjectComponentService} from '../services/inject-component.service';

@NgModule({
  declarations: [
    InjectComponentDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InjectComponentDirective
  ],
  providers: [
    HttpLoaderService,
    InjectComponentService
  ],
})
export class InjectComponentModule {
}
